import { likeRegExp } from '@/offline/database'

export default {
  async _addSubqueriesAnomalia (Vue, rows) {
    for (let row of rows) {
      if (row.material_sistema?.idmaterial_sistema) {
        row.fichaTecnica = Vue.$offline.materialSistema.resumenFichaTecnica(row)
      }
    }
    return rows
  },
  _selectTanomaliaChecklistOtBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.grado_anomalia,
        tables.tanomalia_checklist_ot.idgrado_anomalia.eq(tables.grado_anomalia.idgrado_anomalia)
      )
      .leftOuterJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .leftOuterJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .leftOuterJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .leftOuterJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
  },
  async selectTanomaliaChecklistOt (
    Vue, filter, search, sorter, page, idchecklistOt, idgrupoChecklistOt, idpreguntaChecklistOt, idordenTrabajoMatsist
  ) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.tanomalia_checklist_ot.descripcion.match(reQ),
        )
      )
    }
    let whereQueryFilter = []
    if (idgrupoChecklistOt) {
      whereQueryFilter.push(tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt))
    }
    if (idpreguntaChecklistOt) {
      whereQueryFilter.push(tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt))
    }
    if (idordenTrabajoMatsist) {
      whereQueryFilter.push(tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist))
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
        tables.tanomalia_checklist_ot.estado.gt(0),
        ...whereQueryFilter,
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows =
      await this._selectTanomaliaChecklistOtBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .orderBy(tables.grado_anomalia.orden)
        .orderBy(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot)
        .exec()
    return [(await this._addSubqueriesAnomalia(Vue, rows))]
  },
  async selectTanomaliaChecklistOtRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectTanomaliaChecklistOtBase(Vue)
      .where(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.in(pks))
      .exec()
    return (await this._addSubqueriesAnomalia(Vue, rows))
  },
}
